import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { toKebabCase } from 'util/StringUtil';
import { FormFieldProps, ValidationMessages } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import FieldLabel from './FieldLabel';

export interface BaseInputProps extends FormFieldProps {
	type?: string;
	step?: string;
}

export type InputFieldProps = FieldRenderProps<string, HTMLElement> &
	BaseInputProps & {
		placeholder?: string;
	};

export type HTMLInputRef = React.MutableRefObject<any>;
/**
 * Component that renders an input with form field.
 */
const InputField = React.forwardRef((props: InputFieldProps, ref: HTMLInputRef) => {
	const {
		input: { name, onChange, value },
		meta,
		type,
		label = '',
		isRequired = true,
		disabled = false,
		readOnly = false,
		placeholder = '',
		step,
		testId,
		// #region HCL
		maxLength,
		// #endregion
	} = props;

	return (
		<div className={meta.dirty ? 'roc-form-group roc-form-group--dirty' : 'roc-form-group'}>
			<FieldLabel label={label} name={name} isRequired={isRequired} htmlFor={name} />
			<FieldInputErrorWrapper name={name} inline={type === 'checkbox'}>
				<input
					ref={ref}
					className="roc-form-input__input"
					id={name}
					name={name}
					type={type}
					value={value}
					onChange={onChange}
					disabled={disabled}
					step={step}
					readOnly={readOnly}
					aria-describedby={name + '-invalid'}
					placeholder={placeholder}
					data-testid={testId || `field-${toKebabCase(name)}`}
					// #region HCL
					maxLength={maxLength}
					// #endregion
				/>
			</FieldInputErrorWrapper>
			<ValidationMessages id={name + '-invalid'} meta={meta} />
		</div>
	);
});

export default InputField;
