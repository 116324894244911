import { ServerData } from 'services/ServerData';
import useReCaptchaToken, { UseReCaptchaToken } from './useReCaptchaToken';

/**
 * Reusable hook that calls the generic useReCaptchaToken with key loaded from ServerData
 *
 * @export
 * @param {string} action
 * @returns
 */
export default function useRocReCaptchaToken(action: string, initialEnabled: boolean = true): UseReCaptchaToken {
	const reCaptchaKey = ServerData.ReCaptchaV3Key;
	const isReCaptchaEnabled = initialEnabled && ServerData.IsReCaptchaEnabled;

	// the server will not render anything if the keys aren't set, which means we could get null
	// or even undefined here, so let's just do a truthy check and return an empty string if there
	// is nothing from the server.
	return useReCaptchaToken(reCaptchaKey || '', action, isReCaptchaEnabled);
}
