import { useEffect, useRef } from 'react';

/**
 * Reusable hook that can be used where setInterval is needed.
 *
 * @export
 * @param {() => void} callback
 * @param {(number | null)} delay
 */
export default function useInterval(callback: () => void, delay: number, enabled: boolean = true) {
	const savedCallback = useRef<(() => void) | null>(null);
	// eslint-disable-next-line no-undef
	const intervalId = useRef<NodeJS.Timeout | null>(null);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current!();
		}

		if (delay === null || !enabled) {
			return;
		}

		intervalId.current = setInterval(tick, delay);
		return () => {
			if (intervalId.current) {
				clearInterval(intervalId.current);
			}
		};
	}, [delay, enabled]);
}
