/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { WithTestIdOptional } from 'shared-client/types/test';
import { toKebabCase } from 'util/StringUtil';
import OptionalFieldMarker from '../OptionalFieldMarker';

export interface FieldLabelProps extends WithTestIdOptional {
	label: string;
	name: string;
	id?: string;
	isRequired: boolean;
	htmlFor?: string;
	children?: React.ReactNode;
}

/**
 * Wrapper component for all field labels for ease of use.
 */
export default function FieldLabel(props: FieldLabelProps) {
	const { label, id, isRequired, htmlFor, children, testId } = props;

	if (!label || !label.trim()) {
		return null;
	}

	return (
		<label
			className="roc-form-input-label"
			htmlFor={htmlFor}
			id={id ? id : htmlFor + '-label'}
			data-testid={testId || `field-label-${toKebabCase(label)}`}
		>
			{label}
			{!isRequired ? <OptionalFieldMarker /> : null}
			{children ? children : null}
		</label>
	);
}
