import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import useRocReCaptchaToken from './useRocReCaptchaToken';
import { useLoader } from './useLoader';
import { useRef } from 'react';
import { getCsrfToken } from 'services/UserData';
import AxiosHelper from 'services/AxiosHelper';

/**
 * A reusable hook to be used to submit data via Axios. Handles CSRF and ReCaptcha tokens.
 * Do not call this directly, use useAxiosFormSubmitter or useAxiosStatefulFormSubmitter instead.
 * @param axiosParams The parameters for the request being passed into axios. This includes the method, url, data, etc.
 * @param submitterCallback An optional callback that will be called after a request is submitted. The error and the function to invalidate the used ReCaptcha token will be passed if something goes wrong.
 * @param reCaptchaActionName An optional action name for the ReCaptcha request sent to Google. If left empty, no ReCaptcha token will be sent to the server.
 * @param csrfEnabled Specifies whether CSRF protection should be enabled or not.
 * @param stateful An optional value to determine whether this component should use all useLoader state.
 */
export function useBaseAxiosSubmitter<T, R, K>(
	axiosParams: AxiosRequestConfig,
	submitterCallback?: (response: R, error?: Error | AxiosError, invalidateRecaptchaToken?: () => void) => Promise<K>,
	reCaptchaActionName?: string,
	csrfEnabled = true,
	stateful = false,
) {
	if (axiosParams.method === undefined) {
		axiosParams.method = 'POST';
	}

	const { getToken: getRecaptchaToken, invalidateToken } = useRocReCaptchaToken(reCaptchaActionName || '');
	const { loading, setLoading, error, setError, response, setResponse } = useLoader<AxiosResponse<R>>();
	const cancelToken = useRef<CancelTokenSource>();

	/**
	 * The main method that allows imperative posting of data
	 * @param payload The data that should be posted in the body of the request
	 */
	async function submit(payload: T): Promise<K | undefined> {
		try {
			// avoid duplicate requests if there's a pending one
			if (cancelToken.current) {
				console.warn('Already posting... Did you forget to disable your submit button while this was saving?');
				return;
			}

			cancelToken.current = Axios.CancelToken.source();

			const promises: Array<Promise<string | undefined> | undefined> = [];

			promises.push(csrfEnabled ? getCsrfToken() : undefined);
			promises.push(reCaptchaActionName ? getRecaptchaToken() : undefined);

			const [csrfToken, reCaptchaToken] = await Promise.all<string | undefined>(promises);

			if (stateful) {
				setLoading(true);
				setError(undefined);
			}

			// eslint-disable-next-line no-unused-vars
			const { data, ...rest } = axiosParams;
			const request = {
				...rest,
				data: {
					...payload,
					reCaptchaToken: reCaptchaToken || undefined,
				},
			};

			if (csrfToken) {
				if (!request.headers) {
					request.headers = {};
				}

				request.headers.RequestVerificationToken = csrfToken;
			}

			const serverResponse = (await AxiosHelper(request)) as AxiosResponse<R>;

			// reset cancellation token ref to allow new requests.
			cancelToken.current = undefined;

			setResponse(serverResponse);

			if (submitterCallback !== undefined) {
				return await submitterCallback(serverResponse.data);
			}

			return;
		} catch (error) {
			// reset cancellation token ref to allow new requests.
			cancelToken.current = undefined;

			if (Axios.isCancel(error)) {
				// if the component was unmounted, there's nothing to do
				return;
			}

			if (stateful) {
				setError(error);
			}

			if (submitterCallback !== undefined) {
				return await submitterCallback(error.data, error, invalidateToken);
			}

			return;
		} finally {
			if (stateful) {
				setLoading(false);
			}
		}
	}

	return {
		submitting: loading,
		error,
		response,
		cancelToken: cancelToken.current,
		submit,
	};
}
