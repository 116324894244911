import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { DropdownOption } from 'shared-client/types/fields';
import { toKebabCase } from 'util/StringUtil';
import { FormFieldProps, ValidationMessages } from '.';
import FieldInputErrorWrapper from './FieldInputErrorWrapper';
import FieldLabel from './FieldLabel';
export type DropdownProps = FormFieldProps & {
	addSelectOption?: boolean;
	options: DropdownOption[];
	// #region HCL
	defaultValue?: any;
	// #endregion
};

export type DropdownFieldProps = DropdownProps &
	FieldProps<DropdownOption, FieldRenderProps<DropdownOption, HTMLElement>>;

/**
 * Component that renders a Dropdown field component
 */
export default function DropdownField({
	name,
	isRequired = true,
	readOnly = false,
	disabled = false,
	addSelectOption = true,
	options,
	label = '',
	afterSubmit,
	allowNull,
	beforeSubmit,
	formatOnBlur,
	isEqual,
	subscription,
	validateFields,
	testId,
	// #region HCL
	defaultValue
	// #endregion
}: DropdownFieldProps) {
	return (
		<Field
			name={name}
			afterSubmit={afterSubmit}
			allowNull={allowNull}
			beforeSubmit={beforeSubmit}
			formatOnBlur={formatOnBlur}
			isEqual={isEqual}
			subscription={subscription}
			validateFields={validateFields}
			// #region HCL
			defaultValue={defaultValue?.value}
		    // #endregion
		>
			{({ input: { value, onChange, onFocus, onBlur }, meta }) => (
				<div className={meta.dirty ? 'roc-form-group roc-form-group--dirty' : 'roc-form-group'}>
					<FieldLabel label={label} name={name} isRequired={isRequired} htmlFor={name} />
					<FieldInputErrorWrapper name={name} hideIcon>
						<select
							className="roc-form-input__input"
							id={name}
							name={name}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							onFocus={onFocus}
							disabled={disabled}
							aria-describedby={name + '-invalid'}
							data-testid={testId || `field-${toKebabCase(name)}`}
							// #region HCL
							defaultValue={defaultValue}
						    // #endregion
						>
							{addSelectOption ? <option value="" /> : null}
							{options.map((option, index) => (
								<option
									key={index}
									value={option.value}
									disabled={readOnly}
									data-testid={`field-${toKebabCase(name)}-option-${toKebabCase(option.text)}`}
								>
									{option.text}
								</option>
							))}
						</select>
					</FieldInputErrorWrapper>
					<ValidationMessages id={name + '-invalid'} meta={meta} />
				</div>
			)}
		</Field>
	);
}
