import React from 'react';
import { initReactApp } from '../../../shared/util/ApplicationInit';
import NewsletterSignUp from './NewsletterSignUp';

const render = initReactApp(() => <NewsletterSignUp />, 'newsletter-signup');

render();

// optional, use this to enable hot reloading
if ((module as any).hot) {
	(module as any).hot.accept('./NewsletterSignUp', () => {
		render();
	});
}
