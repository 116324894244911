import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import InputField, { BaseInputProps, HTMLInputRef } from './InputField';

export type InputFieldProps = FieldProps<string, FieldRenderProps<string, HTMLElement>> & BaseInputProps;

/**
 * Component that renders a text box component, of the specified type
 */
const TextBoxField = React.forwardRef((props: InputFieldProps, ref: HTMLInputRef) => {
	const {
		name,
		type = 'text',
		isRequired = true,
		readOnly = false,
		disabled = false,
		placeholder = '',
		label = '',
		validate,
		afterSubmit,
		allowNull,
		beforeSubmit,
		component,
		defaultValue,
		format,
		formatOnBlur,
		initialValue,
		isEqual,
		parse,
		render,
		subscription,
		value,
		testId,
		// #region HCL
		maxLength,
		// # endregion
	} = props;

	return (
		<Field
			name={name}
			validate={validate}
			afterSubmit={afterSubmit}
			allowNull={allowNull}
			beforeSubmit={beforeSubmit}
			component={component}
			defaultValue={defaultValue}
			format={format}
			formatOnBlur={formatOnBlur}
			initialValue={initialValue}
			isEqual={isEqual}
			parse={parse}
			render={render as any}
			subscription={subscription}
			value={value}
		>
			{({ input, meta }) => (
				<InputField
					ref={ref}
					isRequired={isRequired}
					readOnly={readOnly}
					disabled={disabled}
					type={type}
					name={name}
					placeholder={placeholder}
					label={label}
					input={input}
					meta={meta}
					testId={testId}
					// #region HCL
					maxLength={maxLength}
					// #endregion
				/>
			)}
		</Field>
	);
});

export default TextBoxField;
