import { AxiosError, AxiosRequestConfig } from 'axios';
import { handleAxiosErrorWithValidation } from 'shared/util/Misc';
import { useBaseAxiosSubmitter } from './useBaseAxiosSubmitter';
import { Localizer } from 'services/Localizer';

export interface UseAxiosFormSubmitterPostResult {
	formValidationState?: { [index: string]: string };
	error?: Error | AxiosError;
}

export type RocFormSubmitterParams<TResponse> = {
	axiosParams: AxiosRequestConfig;
	reCaptchaActionName?: string;
	csrfEnabled?: boolean;
	stateful?: boolean;
	invalidateTokenOnSuccess?: boolean;
	responseCallback?: (response?: TResponse, error?: Error) => void;
};

export type ResponseCallback<TResponse> = (response?: TResponse, error?: Error, invalidateToken?: () => void) => void;

/**
 * A custom hook that can be used to facilitate posting forms. It handles processing server-side validation automatically.
 * @param axiosParams The parameters for the request being passed into axios. This includes the method, url, data, etc.
 * @param reCaptchaActionName An optional action name for the ReCaptcha request sent to Google. If left empty, no ReCaptcha token will be sent to the server.
 * @param csrfEnabled Specifies whether CSRF protection should be enabled or not.
 * @param stateful A flag for setting the form submitter to store submitting and error state. Not recommended to be true when dealing with react-final-form.
 * @param invalidateTokenOnSuccess A flag for invalidating ReCaptcha token on success. This can be helpful for resetting a form with ReCaptcha.
 * @param responseCallback An optional callback that can be executed on the response or error that is returned.
 */
export function useRocFormSubmitter<TRequest, TResponse = unknown>({
	axiosParams,
	reCaptchaActionName,
	csrfEnabled = true,
	stateful = false,
	invalidateTokenOnSuccess = false,
	responseCallback,
}: RocFormSubmitterParams<TResponse>) {
	return useBaseAxiosFormSubmitter<TRequest, TResponse>(
		axiosParams,
		reCaptchaActionName,
		csrfEnabled,
		stateful,
		invalidateTokenOnSuccess,
		responseCallback,
	);
}

/**
 * Form submitter that only stores the last response in state. Ideal when dealing with react-final-form.
 */
export function useAxiosFormSubmitter<T, R = unknown>(
	axiosParams: AxiosRequestConfig,
	reCaptchaActionName?: string,
	csrfEnabled = true,
	responseCallback?: ResponseCallback<R>,
	invalidateTokenOnSuccess = false,
) {
	return useBaseAxiosFormSubmitter<T, R>(
		axiosParams,
		reCaptchaActionName,
		csrfEnabled,
		false,
		invalidateTokenOnSuccess,
		responseCallback,
	);
}

/**
 * Form submitter that stores submitting and error state. Not recommended when dealing with react-final-form.
 */
export function useStatefulAxiosFormSubmitter<T, R = unknown>(
	axiosParams: AxiosRequestConfig,
	reCaptchaActionName?: string,
	csrfEnabled = true,
	responseCallback?: ResponseCallback<R>,
	invalidateTokenOnSuccess = false,
) {
	return useBaseAxiosFormSubmitter<T, R>(
		axiosParams,
		reCaptchaActionName,
		csrfEnabled,
		true,
		invalidateTokenOnSuccess,
		responseCallback,
	);
}

/**
 * A custom hook that can be used to facilitate posting forms. It handles processing server-side validation automatically.
 * Do not use this hook directly unless you know what you're doing. Prefer useAxiosFormSubmitter, useAxiosStatefulFormSubmitter, or useRocFormSubmitter instead.
 * @param axiosParams The parameters for the request being passed into axios. This includes the method, url, data, etc.
 * @param reCaptchaActionName An optional action name for the ReCaptcha request sent to Google. If left empty, no ReCaptcha token will be sent to the server.
 * @param csrfEnabled Specifies whether CSRF protection should be enabled or not.
 * @param stateful A flag for setting the form submitter to store submitting and error state. Not recommended to be true when dealing with react-final-form.
 * @param invalidateTokenOnSuccess A flag for invalidating ReCaptcha token on success. This can be helpful for resetting a form with ReCaptcha.
 * @param responseCallback An optional callback that can be executed on the response or error that is returned.
 */
export function useBaseAxiosFormSubmitter<T, R>(
	axiosParams: AxiosRequestConfig,
	reCaptchaActionName?: string,
	csrfEnabled = true,
	stateful = false,
	invalidateTokenOnSuccess = false,
	responseCallback?: ResponseCallback<R>,
) {
	if (axiosParams.method === undefined) {
		axiosParams.method = 'POST';
	}

	/**
	 * Callback to be executed after a form submission has taken place.
	 */
	const submitterCallback = (response?: R, error?: Error, invalidateToken?: () => void) => {
		if (error) {
			console.error(error);

			const axiosError = error as AxiosError;
			if (invalidateToken) {
				invalidateToken();
			}

			if (responseCallback !== undefined) {
				responseCallback(response, error);
			}

			return Promise.resolve({
				formValidationState: handleAxiosErrorWithValidation(
					axiosError,
					Localizer('ErrorOccurredSubmittingTheForm'),
				),
				error,
			});
		}

		if (responseCallback !== undefined) {
			responseCallback(response);
		}

		if (invalidateToken && invalidateTokenOnSuccess) {
			invalidateToken();
		}

		return Promise.resolve({});
	};

	const submitter = useBaseAxiosSubmitter<T, R, UseAxiosFormSubmitterPostResult>(
		axiosParams,
		submitterCallback,
		reCaptchaActionName,
		csrfEnabled,
		stateful,
	);

	let result = {
		handleSubmit: async (formValues: T) => {
			const submitResult = await submitter.submit(formValues);
			return submitResult && submitResult.formValidationState;
		},
		submitted: submitter.response !== null && submitter.response.status === 200,
		responseData: submitter.response !== null ? submitter.response.data : null,
	};

	if (stateful) {
		result = {
			...submitter,
			...result,
		};
	}

	return result;
}
