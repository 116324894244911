import React from 'react';
import { LocalizedSpan } from 'services/Localizer';
import { ServerData } from 'services/ServerData';

/**
 * Recaptcha usage disclaimer
 */
export default function ReCaptchaDisclaimer() {
	const reCaptchaKey = ServerData.ReCaptchaV3Key;
	const isReCaptchaEnabled = ServerData.IsReCaptchaEnabled;
	return reCaptchaKey && isReCaptchaEnabled ? (
		<div className="roc-form-group">
			<div className="roc-recaptcha-text">
				<LocalizedSpan
					resourceKey="ProtectedText"
					args={['https://policies.google.com/privacy', 'https://policies.google.com/terms']}
				/>
			</div>
		</div>
	) : null;
}
