import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Pencil SVG
 *
 * @returns
 */
function PencilSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 24 24"
			className={classnames('roc-icon roc-half-half-layout', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path
				d="M16.2928932,2.12089322 L2.79289322,15.6208932 C2.66983759,15.7439488 2.5810257,15.8969877 2.53523618,16.0648826 L1.03523618,21.5648826 C0.831645565,22.3113815 1.51661849,22.9963544 2.26311741,22.7927638 L7.76311741,21.2927638 C7.9310123,21.2469743 8.08405115,21.1581624 8.20710678,21.0351068 L21.7071068,7.53510678 C23.2016311,6.04058249 23.2016311,3.61541751 21.7071068,2.12089322 C20.2125825,0.626368927 17.7874175,0.626368927 16.2928932,2.12089322 Z M20.4051996,3.65799037 C21.0041668,4.37596737 20.9667314,5.44705505 20.2928932,6.12089322 L6.979,19.433 L3.424,20.403 L4.394,16.848 L17.7071068,3.53510678 C18.4205825,2.82163107 19.5794175,2.82163107 20.2928932,3.53510678 L20.4051996,3.65799037 Z"
				id="Stroke-1"
			></path>
		</svg>
	);
}

export default PencilSVG;
