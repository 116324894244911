import { reCaptchaCallbackPromise } from 'features/setup/recaptcha';
import { useLayoutEffect, useRef, useState } from 'react';
import useInterval from './useInterval';

/**
 * Window extended with recaptcha specific fields
 */
interface ReCaptchaWindow extends Window {
	rocReCaptchaCallbacks: Array<() => void>;
	grecaptcha: GoogleRecaptcha;
}

/**
 * Google ReCaptcha Interface
 */
interface GoogleRecaptcha {
	ready(callback: () => void): void;
	execute: (key: string, config: { action: string }) => Promise<string>;
}

export interface UseReCaptchaToken {
	getToken: () => Promise<string>;
	invalidateToken: () => void;
	enabled: boolean;
	setEnabled: (enabled: boolean) => void;
}

let ReCaptchaKeyWarning = false;

/**
 * Reusable hook that generates a recaptcha token from google and generates fresh tokens
 * frequently as these will expire every 2 minutes
 *
 * @export
 * @param {string} reCaptchaKey
 * @param {string} action
 * @returns
 */
export default function useReCaptchaToken(
	reCaptchaKey: string,
	action: string,
	initialEnabled: boolean = !!action,
): UseReCaptchaToken {
	const reCaptchaWindow = (window as unknown) as ReCaptchaWindow;

	// keeps track of the promise that is getting the token across renders. using a ref ensures it updates immediately
	// state updates are async and therefore we cannot read the state value immediately after.
	// there is no longer a callback after setting state with hooks 💣
	const tokenPromise = useRef<Promise<string> | null>(null);

	// store whether the promise is resolved in a ref. same reasoning as above.
	const tokenPromiseIsResolved = useRef(false);

	// this is the promise that will handle waiting on recaptcha initialization
	const initPromise = useRef<Promise<unknown> | null>(null);

	// this will allow for the recaptcha to be disabled on demand or by an invalid token
	const [enabled, setEnabled] = useState(initialEnabled);

	// need to use LayoutEffect because we have to make sure this code runs before google's recaptcha handlers
	// when the dom becomes ready.
	useLayoutEffect(() => {
		(async () => {
			initPromise.current = new Promise(async (resolve) => {
				// wait until google calls this callback.
				if (reCaptchaCallbackPromise) {
					await reCaptchaCallbackPromise;
				}

				// also wait for recaptcha to be ready for us
				reCaptchaWindow.grecaptcha.ready(() => {
					resolve();
					getToken();
				});
			});

			if (!reCaptchaKey && !ReCaptchaKeyWarning) {
				ReCaptchaKeyWarning = true;
				console.warn('No ReCaptchaKey has been set.');
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Invalidates the current token promise as long as it isn't currently unresolved
	 */
	const invalidateToken = async () => {
		// scenario 1: not initialized
		await initPromise.current;

		// if we are working on getting a token, do nothing
		if (!tokenPromiseIsResolved) {
			return;
		}

		tokenPromise.current = getToken(true);
	};

	// tokens expire in 2 minutes, so let's invalidate the token a little more frequently to avoid having a stale one
	useInterval(invalidateToken, 2 * 60 * 1000 - 5000, enabled);

	/**
	 * Returns a promise that will eventually resolve to a fresh recaptcha token
	 */
	const getToken = async (force: boolean = false): Promise<string> => {
		if (!reCaptchaKey || !enabled) {
			// if there's no recaptcha key, we'll simple return an empty string. the server will not validate recaptcha
			// because the key is missing.
			return Promise.resolve('');
		}

		// wait for the callback to fire
		await initPromise.current;

		// first time or if we need a new one
		if (tokenPromise.current === null || force) {
			tokenPromiseIsResolved.current = false;

			tokenPromise.current = new Promise(async (resolve) => {
				try {
					const result = await reCaptchaWindow.grecaptcha.execute(reCaptchaKey, { action });
					resolve(result);
				} catch (error) {
					console.warn(
						'ReCaptcha warning:',
						error?.message || 'An unexpected ReCaptcha error occurred. Ensure you have a valid key.',
					);
					setEnabled(false);
					resolve('');
				}
				tokenPromiseIsResolved.current = true;
			});
		}

		// in some cases this is the existing promise because it not resolved yet, so we don't want to
		// start another request separately.
		return tokenPromise.current;
	};

	return { getToken: () => getToken(), invalidateToken, enabled, setEnabled };
}
