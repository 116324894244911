import React from 'react';
import { Localizer } from 'services/Localizer';
import Spinner from 'shared/components/Spinner';
import { TextBoxField } from 'shared/edit/fields';
import ReCaptchaDisclaimer from 'shared/edit/fields/ReCaptchaDisclaimer';
import InlineAlert from 'shared/edit/InlineAlert';
import RocForm from 'shared/edit/RocForm';
import { useAxiosFormSubmitter } from 'shared/hooks/useBaseAxiosFormSubmitter';

export interface NewsletterSignUpValues {
	email: string;
	interestedIn: string;
}

/**
 * Handles newsletter sign-ups
 */
export default function NewsletterSignUp() {
	const formSubmitter = useAxiosFormSubmitter<NewsletterSignUpValues>(
		{
			url: '/ajax/newsletter',
		},
		'Newsletter',
	);

	return !formSubmitter.submitted ? (
		<RocForm<NewsletterSignUpValues>
			onSubmit={(formValues) => {
				return formSubmitter.handleSubmit(formValues);
			}}
			render={({ handleSubmit, submitError, submitting }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="roc-footer__sign-up-email-wrapper">
							<p>
								<strong className="roc-footer__contact-title">
									{Localizer('SubscribeForOurEmail')}
								</strong>
							</p>

							<div className="roc-input-group roc-footer__sign-up-email">
								<TextBoxField
									name="email"
									label={Localizer('Email')}
									className="roc-form-input__input"
									placeholder={Localizer('Email')}
								/>
								<TextBoxField
									name="interestedIn"
									label={Localizer('MostlyInterestedIn')}
									className="roc-form-input__input"
									placeholder={Localizer('InterestedInPlaceholderText')}
								/>
								<button
									type="submit"
									id="btnNewsletterSignUp"
									disabled={submitting}
									className="roc-btn"
								>
									{Localizer('SignUp')} {submitting ? <Spinner light /> : null}
								</button>
							</div>

							<ReCaptchaDisclaimer />
							{submitError ? <InlineAlert message={submitError} error={null} /> : null}
						</div>
					</form>
				);
			}}
		/>
	) : (
		<div className="ctct-inline-form">
			<div className="ctct-form-container ctct-form-embed form_0">
				<div className="ctct-form-defaults"></div>
				<div id="success_message_0" className="ctct-form-success">
					<h2 className="ctct-form-header">{Localizer('ThanksForSigningUp')}</h2>
				</div>
			</div>
		</div>
	);
}
