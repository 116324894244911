import { AxiosError } from 'axios';
import { FORM_ERROR } from 'final-form';
import { unflatten } from 'flat';

export const getFormattedPageTitle = (subTitle: string = ''): string => {
	// load from config?
	const siteName = 'ROC';

	if (!subTitle || !subTitle.trim()) {
		return siteName;
	}

	return `${subTitle.trim()} - ${siteName}`;
};

/**
 * Promise version of setTimeout.
 * @param ms The number of milliseconds to wait for.
 */
export const timeout = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Allows you to await on a timeout, useful for avoiding setTimeout callbacks and for troubleshooting.
 * @param ms The number of milliseconds to wait for.
 */
export const sleep = async (ms: number = 0) => {
	await timeout(ms);
};

/**
 * Handles Axios error for React Final Form. If the error is a validation error, the error is transformed into React
 * Final Form field validation object. If the error is a generic error, the error is rethrown instead.
 *
 * @param error - The Axios Error object to handle errors for.
 * @param errorMessage - The form error message to provide back to React Final Form.
 * @param validationErrorCallback - Optional callback that is triggered right before the validation object is returned
 * to Final Form, so that you can run logic that should occur during validation errors.
 * @param genericErrorCallback - Optional callback that is triggered right before the generic error is rethrown, so that
 * you can run logic that should occur during generic errors.
 */
export const handleAxiosErrorWithValidation = (
	error: AxiosError,
	errorMessage: string | null = 'An error occurred while saving.',
	validationErrorCallback?: () => void,
	genericErrorCallback?: () => void,
): { [index: string]: string } => {
	if (error?.response?.status === 400 && error.response.data) {
		let globalErrors;

		const errors = error.response.data;

		const errorsWithModifiedArrayIndex = Object.keys(errors).reduce((acc, value) => {
			if (value === '') {
				// in case we are getting back a non field specific error store it in the global errors
				globalErrors = errors[value];
			} else {
				const newKey = value.replace(/\[([0-9]+)\]/g, '.$1');
				acc[newKey] = errors[value];
			}

			return acc;
		}, {});

		const newValidationState = { ...unflatten(errorsWithModifiedArrayIndex) };

		console.debug('newValidationState:', newValidationState);

		if (validationErrorCallback) {
			validationErrorCallback();
		}

		return { ...newValidationState, [FORM_ERROR]: globalErrors ? globalErrors : errorMessage };
	} else {
		// generic error.
		if (genericErrorCallback) {
			genericErrorCallback();
		}

		if (error?.response?.data?.message) {
			return { [FORM_ERROR]: error.response.data.message };
		}

		if (errorMessage) {
			return { [FORM_ERROR]: errorMessage };
		} else {
			return {};
		}
	}
};

/**
 * Get an html class or ID safe string from provided string.
 *
 * @function getHtmlSafeName
 */
export const getHtmlSafeName = (name: string = ''): string => {
	return name.replace(/[^a-z0-9]/g, (s) => {
		const c = s.charCodeAt(0);
		if (c === 32) {
			return '-';
		}
		if (c >= 65 && c <= 90) {
			return '_' + s.toLowerCase();
		}
		return '__' + ('000' + c.toString(16)).slice(-4);
	});
};

/**
 * Gets the specified param value from the querystring
 *
 * @param paramName the name of the query string parameter
 * @param decodeParam specifies if the param value should be decoded before returning it
 */
export const getQueryParam = (paramName: string = '', decodeParam: boolean = false): string | null => {
	const urlParams = new URLSearchParams(window.location.search);
	const paramValue = urlParams.get(paramName);

	if (!paramValue) {
		return null;
	}

	if (decodeParam) {
		return decodeURIComponent(paramValue);
	} else {
		return paramValue;
	}
};
